/*
  Note: the field name Number collides with a global object so be careful when importing this independently of the index. --BLR
*/

import {Component} from 'react'

import formFieldFactory from '../formFieldFactory'
import Input from './Input'


class Number extends Component {
  value() { return this.refs.formInput.value() }

  render() { return <Input {...this.props} ref='formInput' type='number' /> }
}

export default formFieldFactory(<Number />)
