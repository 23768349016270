import classnames from 'classnames'

const FieldSet = ({children, className}) => (
  <fieldset>
    <div className={classnames('fieldset-flexbox', className)}>
      {children}
    </div>
  </fieldset>
)

export default FieldSet
