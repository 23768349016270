import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import {Label} from './Labels'
import FieldSet from './FieldSet'
import formFieldFactory from '../formFieldFactory'
import FieldErrors from './FieldErrors'

export class SimpleCheckbox extends React.Component {
  value() { return this.refs.input.checked }

  render() {
    const {data, errors, ...otherProps} = this.props

    return (
      <FieldSet className='controls-group checkbox-form-group service-terms'>
        <Label errors={errors}>
          <input
            {...otherProps}
            name={data.get('name')}
            ref='input'
            type='checkbox'
            value={data.get('value')}
          />
          <span className='label-text'>{data.get('legend')}</span>
          <FieldErrors errors={errors} />
        </Label>
      </FieldSet>
    )
  }
}

SimpleCheckbox.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List)
}

export default formFieldFactory(<SimpleCheckbox />)
