import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'
import {fromJS, Map} from 'immutable'

import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import formFieldFactory from '../formFieldFactory'
import {Placeholder} from './Labels'


import './select.scss'

// This is our custom select controls component. It contains a lot of moving parts, so lets do a tutorial:
/*
 * I. Web Accessibility
 * In order to allow the end user who use screen readers or keyboards, we needed to add the following ARIA tags and methods:
 *
 *   Aria Tags
 *   aria-activedescendant - Informs any assistive technology which option in the list is visulally focused.
 *   aria-expanded - Indicates whether the element, or another grouping element it controls, is currently expanded or collapsed.
 *   role - Provide additional information about the semantic meaning of an HTML element to screen readers.
 *     * combobox - The combined presentation of a single line textfield with a `listbox` popup (ideal for auto-completes)
 *     * listbox - Used to identify an element that creates a list from which a user may select one or more items.
 *     * option - A selectable item in a select list and is owned by an element with the role `listbox`.
 *
 *   Methods
 *   checkKeyPress() - This method handles keypress events that are similar the the browser's default select field: `escape`, `spacebar`, `up-arrow`, and `down-arrow`.
 *   The method filters the pressed key and calls the approriate helper method. Keypress events:
 *     * escape - Close the `listbox` if open & revert to the previously selected value to the `aria-activedescendant` and `input` tags.
 *     * spacebar - Toggles open/close the `listbox`. If an `option` item has focus, it will be selected and add it's value to the `aria-activedescendant` and `input` tags and close the `listbox`.
 *     * up-arrow, down-arrow - Opens the `listbox` if closed. Moves the focus of the `option` item in the `listbox`.
 *
 *   moveFocusDownOneOption(), moveFocusUpOneItem() - Handles the `down-arrow` and `up-arrow` keypress events. Moves the focus of the `option` item in the `listbox` and
 *   stores it's value to the `aria-activedescendant` tag.
 *
 *
 * II. Select-like Methods
 * Some additional methods to allow our custom select control behave like a standard select field:
 *
 *   closeDropdownAndSetCurrentChoice() - Closes the `listbox` if open and selects the focused `option` item.
 *
 *   toggleFocusedOption() - Moves the `option` item's focus from the `onMouseOver` / `onKeyDown` synthetic event.
 *
*/

// Key Code Values
const DOWN_ARROW = 40
const ENTER = 13
const ESCAPE = 27
const SPACEBAR = 32
const UP_ARROW = 38

export class Select extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ariaActivedescendant: '',
      ariaExpanded: false,
      selectValue: props.data.get('value') || props.data.get('items').first().get('value')
    }

    this.closeDropdown = this.closeDropdown.bind(this)
    this.findFocusedOption = this.findFocusedOption.bind(this)
    this.findSelectedOption = this.findSelectedOption.bind(this)
    this.getActiveDescendant = this.getActiveDescendant.bind(this)
    this.itemValue = this.itemValue.bind(this)
    this.getOptionGroup = this.getOptionGroup.bind(this)
    this.checkKeyPress = this.checkKeyPress.bind(this)
    this.closeDropdownAndSetCurrentChoice = this.closeDropdownAndSetCurrentChoice.bind(this)
    this.toggleFocusedOptionOnMouseHover = this.toggleFocusedOptionOnMouseHover.bind(this)
    this.moveFocusDownOneOption = this.moveFocusDownOneOption.bind(this)
    this.moveFocusUpOneItem = this.moveFocusUpOneItem.bind(this)
    this.openDropdown = this.openDropdown.bind(this)
    this.selectOption = this.selectOption.bind(this)
    this.setActiveDescendant = this.setActiveDescendant.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.toggleFocusedOption = this.toggleFocusedOption.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.data.get('value')

    if (this.props.data.get('value') !== value)
      this.setState({selectValue: value})
  }

  componentDidMount() {
    document.addEventListener('click', this.closeDropdownAndSetCurrentChoice)
    window.addEventListener('blur', this.closeDropdown)
    this.findSelectedOption().classList.add('focused')
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.selectValue !== this.state.selectValue)
      this.props.onChange()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdownAndSetCurrentChoice)
    window.removeEventListener('blur', this.closeDropdown)
  }

  closeDropdown() { this.setState({ariaExpanded: false}) }

  openDropdown() {
    this.findFocusedOption() // Will set focus on the first item if no focus is set.
    this.setState({ariaExpanded: true})
  }

  getActiveDescendant() { return this.refs.selectField.getAttribute('aria-activedescendant') }

  setActiveDescendant(itemValue) { this.refs.selectField.setAttribute('aria-activedescendant', itemValue) } // Update ARIA attribute to ensure screen readers are alerted to the change in focus.

  itemValue(itemElement) { return itemElement.getAttribute('data-value') }

  getOptionGroup() { return this.refs.listbox.children }

  findFocusedOption() {
    return Array.prototype.find.call(this.getOptionGroup(), item => {
      if (item.classList.contains('focused'))
        return item
    })
  }

  findSelectedOption() {
    return Array.prototype.find.call(this.getOptionGroup(), item => {
      if (item.getAttribute('data-value') === this.refs.selectField.value)
        return item
    })
  }

  checkKeyPress(event) {
    const keyCode = event.keyCode

    // Open dropdown if it's currently closed:
    if (!this.state.ariaExpanded) {
      if ([DOWN_ARROW, SPACEBAR, UP_ARROW].includes(keyCode))
        this.toggleDropdown()

      return
    }

    // Close dropdown when user hits escape:
    if (keyCode === ESCAPE) {
      const currentSelectedOption = this.findSelectedOption()
      const currentSelectedOptionValue = this.itemValue(currentSelectedOption)
      const focusedOption = this.findFocusedOption()
      const focusedOptionValue = this.itemValue(focusedOption)

      if (focusedOptionValue !== currentSelectedOptionValue)
        this.toggleFocusedOption(focusedOption, currentSelectedOption)

      this.closeDropdown()
      return
    }

    // Choose an option and close the menu if user hits enter or space:
    if ([ENTER, SPACEBAR].includes(keyCode)) {
      event.preventDefault()
      this.selectOption(this.findFocusedOption())
      this.closeDropdown()
      return
    }

    // Otherwise, navigate within the dropdown if the user hit the up or down arrow:
    if (keyCode === DOWN_ARROW)
      return this.moveFocusDownOneOption()

    if (keyCode === UP_ARROW)
      return this.moveFocusUpOneItem()
  }

  closeDropdownAndSetCurrentChoice(event) {
    // The check here makes sure we are clicking an actual element before attempting to close the dropdwon
    if (this.state.ariaExpanded && event.target.name && event.target.name !== this.refs.listbox.id)
      this.closeDropdown()

    if (event.target.getAttribute('name') === this.refs.listbox.id && event.target.getAttribute('role') === 'option')
      this.selectOption(event.target)
  }

  toggleFocusedOptionOnMouseHover(event) { this.toggleFocusedOption(this.findFocusedOption(), event.target) }

  moveFocusDownOneOption() {
    const focusedOption = this.findFocusedOption()
    const toFocus = focusedOption.nextSibling

    if (toFocus) // Move focus to the next sibling if the `focusedOption` is not the last item in the options list.
      this.toggleFocusedOption(focusedOption, toFocus)
  }

  moveFocusUpOneItem() {
    const focusedOption = this.findFocusedOption()
    const toFocus = focusedOption.previousSibling

    if (toFocus) // Move focus to the previous sibling if the `focusedOption` is not the first item in the options list.
      this.toggleFocusedOption(focusedOption, toFocus)
  }

  selectOption(element) { this.setState({selectValue: this.itemValue(element)}) }

  toggleDropdown() { this.state.ariaExpanded ? this.closeDropdown() : this.openDropdown() }

  toggleFocusedOption(focusedOption, toFocus) {
    if (!focusedOption.classList.contains('disabled')) {
      focusedOption.classList.remove('focused')
      toFocus.classList.add('focused')
      this.setActiveDescendant(this.itemValue(toFocus))
    }
    // TODO: Handle Options that are disabled. Trello card: https://trello.com/c/xyy8dtZR/ --KTW
  }

  inputProps() {
    return {
      'aria-activedescendant': this.state.ariaActivedescendant,
      'aria-expanded': this.state.ariaExpanded,
      autoComplete: 'nofill', // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion --KTW
      className: 'custom-select',
      disabled: this.props.disabled,
      name: this.props.data.get('name'),
      onChange: this.props.onChange,
      onClick: this.toggleDropdown,
      onKeyDown: this.checkKeyPress,
      readOnly: 'readonly', // HTML5 attribute which disables the mobile keyboard from displaying. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input --KTW
      ref: 'selectField',
      role: 'combobox',
      type: 'text',
      value: this.state.selectValue
    }
  }

  optionProps(option) {
    return {
      className: classnames({disabled: option.get('disabled')}),
      'data-value': option.get('value'),
      'data-default-option': option.get('defaultOption'),
      disabled: option.get('disabled'),
      id: `${this.props.data.get('name')}-${option.get('label')}`,
      onMouseOver: this.toggleFocusedOptionOnMouseHover,
      name: this.props.data.get('name'),
      role: 'option'
    }
  }

  // Must be a method so it can be called via formFieldFactory.
  value() { return this.state.selectValue }

  render() {
    const {data, errors, ...otherProps} = this.props
    const legend = data.get('legend')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className='controls-flexbox flex-container'>
          <label className={classnames('select', 'flex-child', this.props.className, {open: this.state.ariaExpanded, error: errors && errors.size})}>
            <span className='label-text visually-hidden'>Label text</span>
            <div className='arrow-up' />
            <Placeholder data-placeholder={this.state.selectValue === '' ? this.props.data.getIn(['items', 0, 'label']) : ''}>
              <input {...this.inputProps()} />
            </Placeholder>
            <ul className='dropdown-options' id={this.props.data.get('name')} ref='listbox' role='listbox'>
              {
                this.props.data.get('items') &&
                (
                  this.props.data.get('items').map(option => (
                    <li key={option.get('id') ? option.get('id') : option.get('label')} {...this.optionProps(option)}>{/* Left key here to prevent ESLint from whining. --BLR */}
                      {option.get('image') && <img src={option.get('image')} alt={option.get('label')} />}
                      {option.get('label')}
                    </li>
                  ))
                )
              }
            </ul>
          </label>
        </div>
        <FieldErrors errors={errors} />
      </FieldSet>
    )
  }
}

Select.defaultProps = {
  data: fromJS({items: [{}]}), // Complex default is needed to prevent issues when setting initially-chosen option. --BLR
  selectFormField: true // Flag to make formField.react know it's a select field - KAY
}

Select.propTypes = {
  data: PropTypes.instanceOf(Map),
  disabled: PropTypes.bool,
  selectFormField: PropTypes.bool
}

export default formFieldFactory(<Select />)
