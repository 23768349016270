import {Component} from 'react'

import formFieldFactory from '../formFieldFactory'
import Input from './Input'


class Tel extends Component {
  value() { return this.refs.formInput.value() }

  render() { return <Input {...this.props} ref='formInput' type='tel' /> }
}

export default formFieldFactory(<Tel />)
