import PropTypes from 'prop-types'


const DeleteIcon = ({fill, height, width, ...otherProps}) => (
  <svg {...otherProps} data-testid={`delete-icon-${otherProps.id}`} height={height} width={width} version='1.1' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <path fill={fill} d='M17.85,25.3846154 C18.5110465,25.3846154 19.0545349,24.8548352 19.0702326,24.2268132 L19.425,9.99296703 C19.4410465,9.35571429 18.9073256,8.81934066 18.2333721,8.80450549 C17.5486047,8.79494505 16.9995349,9.28978022 16.9838372,9.9267033 L16.6287209,24.1747253 C16.6130233,24.811978 17.1463953,25.3846154 17.8206977,25.3846154 L17.85,25.3846154 Z M12.0966279,25.3846154 L12.1259302,25.3846154 C12.7998837,25.3846154 13.3336047,24.7717582 13.317907,24.1345055 L12.9627907,9.88153846 C12.947093,9.24428571 12.394186,8.72373626 11.7136047,8.73791209 C11.0396512,8.75274725 10.5059302,9.27263736 10.5219767,9.90989011 L10.8767442,24.1931868 C10.8924419,24.8208791 11.4355814,25.3846154 12.0966279,25.3846154 L12.0966279,25.3846154 Z M11.5116279,3.95604396 L18.4883721,3.95604396 L18.4883721,2.30769231 L11.5116279,2.30769231 L11.5116279,3.95604396 Z M30,5.27472527 C30,6.00296703 29.3752326,6.59340659 28.6046512,6.59340659 L1.39534884,6.59340659 C0.624767442,6.59340659 0,6.00296703 0,5.27472527 C0,4.54648352 0.624767442,3.95604396 1.39534884,3.95604396 L9.06976744,3.95604396 L9.06976744,0.989010989 C9.06976744,0.351758242 9.5194186,0 10.1940698,0 L19.8059302,0 C20.4802326,0 20.9302326,0.351758242 20.9302326,0.989010989 L20.9302326,3.95604396 L28.6046512,3.95604396 C29.3752326,3.95604396 30,4.54648352 30,5.27472527 L30,5.27472527 Z M26.3012791,9.68835165 L25.2362791,28.7508791 C25.1972093,29.4510989 24.5853488,30 23.8430233,30 L6.15697674,30 C5.415,30 4.80313953,29.4510989 4.76372093,28.7508791 L3.69906977,9.68835165 C3.65825581,8.9610989 4.24953488,8.34032967 5.01872093,8.30208791 C5.79209302,8.26681319 6.44546512,8.82230769 6.48593023,9.54923077 L7.48046512,27.3626374 L22.5195349,27.3626374 L23.5140698,9.54923077 C23.5548837,8.82197802 24.2162791,8.26912088 24.9812791,8.30208791 C25.7504651,8.34032967 26.3417442,8.9610989 26.3012791,9.68835165 L26.3012791,9.68835165 Z' />
    </g>
  </svg>
)

DeleteIcon.defaultProps = {
  fill: '#00000',
  height: '18',
  width: '18'
}

DeleteIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default DeleteIcon
