import {Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import {Label} from './Labels'


export default class Checkbox extends React.Component { // Must be a class because it needs to have a ref attached to it and have a child ref too. --BLR
  checked() { return this.refs.input.checked } // Helper for parent to grab checked status of the input. --BLR

  render() {
    const {item, ...otherProps} = this.props
    return (
      <Label>
        <input ref='input' type='checkbox' value={item.get('value')} {...otherProps} />
        <span className='label-text'>
          {item.get('image') && <img src={item.get('image')} alt={item.get('label')} />}
          <div>{item.get('label')}</div>
        </span>
      </Label>
    )
  }
}

Checkbox.propTypes = {
  item: PropTypes.instanceOf(Map)
}
