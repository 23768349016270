import {fromJS, List, Map} from 'immutable'
import moment from 'moment'
import Payment from 'payment'
import validate from 'validate.js'

import {capitalize, removeDashesAndCapitalize, isValidDate} from '../../../lib/tools'
import {trimString} from '../../../lib/textTools'
import {birthdateValidatorFormat, dateFromForm} from '../../../lib/dateTools'
import criteria from '../../../shared_components/password_criteria.json'

validate.extend(validate.validators.datetime, { // taken from validate.js docs -- BJK
  // The value is guaranteed not to be null or undefined but otherwise it could be anything.
  parse(value) { return +moment.utc(value) },
  // Input is a unix timestamp
  format(value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
    return moment.utc(value).format(format)
  }
})

// `presence: true` doesn't handle all situations our forms present. This acts as a backup.
validate.validators.datetimePresenceBackup = (value, options, key) => {
  if (value === null)
    return `^Please enter a date${key === 'dob' || key === 'birthdate' ? ' of birth.' : ''}`

  if (!isValidDate(value))
    return '^Please enter a valid date.'

  if (moment.utc().subtract(13, 'years') < value)
    return '^You must be at least 13 years old to use Everplans.'

  return ''
}

const template = (name, data, constraints) => {
  let errors = validate(data, constraints, {format: 'flat'})
  return errors ? fromJS(errors) : List()
}

const groupedErrorsTemplate = (name, data, constraints) => {
  const errors = validate(
    data[name],
    constraints,
    {format: 'grouped'}
  )

  return errors ? fromJS(errors) : List()
}

const required = (name, data) => {
  const constraints = {
    [name]: {
      presence: {
        message: `^${removeDashesAndCapitalize(name)} can't be blank.`
      }
    }
  }
  return template(name, data, constraints)
}

const checkboxCheckedValidator = (name, data, message = 'You must check the box above.') => {
  if (data[name] !== true)
    return fromJS([message])
}

export default {
  checkboxCheckedValidator,
  template,
  required,
  birthdateValidator(name, data) {
    const formattedData = {[name]: birthdateValidatorFormat(data[name])}

    let constraints = {
      [name]: {
        /*
          `presence: true` does not check for `Invalid Date` and can cause multiple errors
          ensures that all DateInputs will throw the same errors and checks for age
          currently the backup only works for birthdates, if implementing other date types pass an object instead of true --NP
        */
        datetimePresenceBackup: true
      }
    }
    return template(name, formattedData, constraints)
  },

  emailValidator(name, data) {
    let constraints = {}
    constraints[name] = {email: {message: '^Email is not valid.'}, presence: true}
    data.email = trimString(data.email)
    return template(name, data, constraints)
  },

  emailNotRequiredValidator(name = 'email', data) {
    let constraints = {}
    constraints[name] = {email: {message: '^Email is not valid.'}}
    return template(name, data, constraints)
  },

  radioListValidator(name, data) {
    let constraints = {}
    constraints[name] = {presence: {message: '^Please make a selection above.'}}
    return template(name, data, constraints)
  },

  positiveInteger(name, data) {
    let constraints = {}
    constraints[name] = {
      numericality: {
        greaterThanOrEqualTo: 0,
        message: '^Must be zero or positive number.',
        onlyInteger: true
      },
      presence: true
    }
    return template(name, data, constraints)
  },

  requiredImmutableList(name, data) {
    if (data[name] && data[name].size)
      return List()

    return fromJS([`${capitalize(name).split('-').join(' ')} can't be blank.`])
  },

  urlValidator(name, data) {
    let constraints = {}
    constraints[name] = {
      format: {
        message: '^URL must start with http:// or https://',
        pattern: /^https?:\/\/.+\..+/i
      }
    }
    return template(name, data, constraints)
  },

  urlStringValidator(name, data) {
    const constraints = {}
    constraints[name] = {
      format: {
        message: '^Must be a valid URL string.',
        pattern: /^[a-z0-9_\-]+$/
      }
    }
    return template(name, data, constraints)
  },

  hexCodeValidator(name, data) {
    const constraints = {}
    constraints[name] = {
      format: {
        message: '^Must be a valid hex code.',
        pattern: /^#([a-fA-F0-9]{3}){1,2}$/
      }
    }
    return template(name, data, constraints)
  },

  creditCardNumberValidator(name, data) {
    let validation = Payment.fns.validateCardNumber(data.creditCardNumber)
    let errors = {name, errors: validation ? null : 'Please enter a valid card number.'}
    if (errors.errors)
      return errors
  },

  creditCardDateAndCVCValidator(name, data) {
    let errors = []
    if (!Payment.fns.validateCardExpiry(data.expirationDate)) errors.push('Please enter a valid expiration date.')
    if (!Payment.fns.validateCardCVC(data.cvc)) errors.push('Please enter a valid CVC number.')
    if (errors && errors.length)
      return {name, errors}
  },

  requiredNameFirstLastMiddleValidator(name, data) {
    return groupedErrorsTemplate(
      name,
      data,
      {
        'first-name': {
          presence: {message: '^Please add your first name.'}},
        'last-name': {
          presence: {message: '^Please add your last name.'}},
        'middle-name': {
          presence: {message: '^Please add your middle name.'}}
      }
    )
  },

  requiredNameFirstLastValidator(name, data) {
    return groupedErrorsTemplate(
      name,
      data,
      {
        'first-name': {
          presence: {message: '^Please add your first name.'}},
        'last-name': {
          presence: {message: '^Please add your last name.'}}
      }
    )
  },

  requiredPronounsValidator(name, data) {
    if (!data.pronouns)
      return 'Please select one.'
  },

  deleteAccountValidator(name, data) {
    if (data[name] !== 'delete' || !data[name])
      return List(['The box does not contain the delete word.'])
    else
      return List()
  },

  responseDateValidator(name, data) {
    const errors = []
    const dateValue = Map(data[name])

    if (!dateValue.isEmpty() && dateFromForm(dateValue) === 'Invalid date')
      errors.push('Please add a valid date.')

    if (dateValue.get('year') && dateValue.get('year').toString().length !== 4)
      errors.push('Please add a 4 digit year.')

    return List(errors)
  },

  passwordValidator(name, data) {
    const passwordData = data[name] || {}
    const password = passwordData.password || ''

    if (password.length === 0)
      return fromJS({password: ["Password can't be empty."]})

    if (password !== passwordData['password-confirmation'])
      return fromJS({'password-confirmation': ["Passwords don't match. Please try again."]})

    let isValid = true
    criteria.forEach(criterion => { isValid = isValid && criterion.regex.test(password) })
    if (!isValid)
      return fromJS({password: ['Please enter a valid password.']})
  },

  zipOrCountryValidator(name, data) {
    const zipOrCountryData = data[name] || {}
    const zip = zipOrCountryData.zip
    const countryCode = zipOrCountryData['country-code']

    if (!zip && !countryCode)
      return fromJS(['Please enter a ZIP or a country code.'])

    if (zip) {
      const trimData = {...zipOrCountryData, zip: zip.trim()}

      return template(name, trimData, {zip: {presence: true, format: /\d{5}(-\d{4})?/}})
    } else {
      return template(name, zipOrCountryData, {'country-code': {presence: true}})
    }
  },

  zipCodeValidator(name, data) {
    return template(name, data, {zip: {presence: true, format: /\d{5}(-\d{4})?/}})
  },
  serviceTermAndPrivacyPolicyValidator(name, data) {
    const message = 'You must agree to the terms of service and privacy policy.'
    return checkboxCheckedValidator(name, data, message)
  },

  // This is a closure that allows the validator field name to be customized. The function
  // must be triggered to return a valid required validator.
  // E.g requiredCustomFieldNameCallback('hello world')
  // => hello world can't be empty.
  requiredCustomFieldNameCallback(fieldName) {
    return (formFieldId, data) =>
      required(fieldName, {[fieldName]: data[formFieldId]})
  }
}
