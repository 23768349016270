import classnames from 'classnames'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import RadioButton from './RadioButton'

import formFieldFactory from '../formFieldFactory'


export class RadioList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {value: props.data.get('value')}

    this.onChange = this.onChange.bind(this)
  }

  value() { return Map().set(this.props.data.get('id'), this.state.value) }

  onChange(event) {
    this.setState({value: event.target.value}, () => {
      if (this.props.onChange)
        this.props.onChange()
    })
  }

  isChecked(value) { return this.state.value === value }

  render() {
    const {className, data, errors, ...props} = this.props
    const legend = data.get('legend')

    return (
      <FieldSet className={classnames('controls-group', className)}>
        {legend && <legend>{legend}</legend>}
        <div className={classnames('controls-flexbox flex-container radio-form-group', {error: errors && errors.size})}>
          {
            data.get('items').map(
              item => (
                <RadioButton
                  {...props}
                  value={item.get('value')}
                  checked={this.isChecked(item.get('value'))}
                  image={item.get('image')}
                  item={item}
                  key={item.get('label')}
                  name={data.get('name')}
                  ref={item.get('value')}
                  onChange={this.onChange}
                />
              )
            )
          }
        </div>
        <FieldErrors errors={errors} />
      </FieldSet>
    )
  }
}

RadioList.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List)
}

export default formFieldFactory(<RadioList />)
