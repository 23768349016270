import { List } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'


const FieldErrors = ({errors}) => (
  <div className='field-errors'>
    {
      errors.map(
        error => (
          <div className='error' key={error}>
            <div className='error-text'>
              {error}
            </div>
          </div>
        )
      )
    }
  </div>
)

FieldErrors.defaultProps = {
  errors: List()
}

FieldErrors.propTypes = {
  errors: PropTypes.instanceOf(List)
}

export default FieldErrors

