import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'
import {fromJS, List, Map} from 'immutable'

import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import {Accessible, Placeholder} from './Labels'

import formFieldFactory from '../formFieldFactory'
import {noop} from '../../../../lib/tools'
import {onBlur, onFocus} from '../../../../dev_only/forms_playground/lib/tools'


export class Name extends Component {
  /**
   * Only rerender if :
   *   - Any relevant subfields of the full name have changed;
   *   - `errors` applicable to this field have changed; or,
   *   - The parent `Form` has announced that `errors` should be displayed or hidden.
   * Due to the `middle-name` being conditionally present the `showMiddleInitial`
   * boolean needs to be combined into the check of the change in that subfield.
   */
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.errors !== this.props.errors ||
      nextProps.shouldShowErrors !== this.props.shouldShowErrors ||
      nextProps.value.get('first-name') !== this.props.value.get('first-name') ||
      nextProps.value.get('last-name') !== this.props.value.get('last-name') ||
      nextProps.fieldType !== this.props.fieldType ||
      (
        nextProps.showMiddleInitial &&
        nextProps.value.get('middle-name') !== this.props.value.get('middle-name')
      )
    )
  }

  value() {
    let fieldData = fromJS({
      'first-name': this.refs.first_name.value,
      'last-name': this.refs.last_name.value
    })

    // Only include middle initial if it's on the DOM to prevent unintentional deletion of existing middle initial:
    if (this.props.showMiddleInitial)
      fieldData = fieldData.set('middle-name', this.refs.middle_name.value)

    return fieldData
  }

  inputProps(namePart, value, otherProps) {
    const namePartValue = value && value.get(`${namePart}-name`) ? value.get(`${namePart}-name`) : ''
    return {
      defaultValue: namePartValue,
      name: `${namePart}-name`,
      ref: `${namePart}_name`,
      type: this.props.fieldType || 'text',
      onFocus,
      onBlur,
      value: namePartValue,
      ...otherProps
    }
  }

  render() {
    const {data, errors, showMiddleInitial, value, ...otherProps} = this.props

    return (
      <FieldSet className='name controls-group'>
        <legend>{data.get('legend') ? data.get('legend') : 'Name'}</legend>
        <div className={classnames('controls-flexbox', 'flex-container', 'name-form-group', {'with-middle-initial': showMiddleInitial})}>
          <Placeholder className={classnames('first-name', {error: errors && errors.getIn([0, 'first-name'])})} data-placeholder='First' hasValue={value && value.get('first-name')}>
            <Accessible>First name</Accessible>
            <input {...this.inputProps('first', value, otherProps)} />
          </Placeholder>
          {
            showMiddleInitial &&
            (
              <Placeholder className={classnames('middle-initial', {error: errors && errors.getIn([0, 'middle-name'])})} data-placeholder='MI' hasValue={value && value.get('middle-name')}>
                <Accessible>Middle initial</Accessible>
                <input {...this.inputProps('middle', value, otherProps)} />
              </Placeholder>
            )
          }
          <Placeholder className={classnames('last-name', {error: errors && errors.getIn([0, 'last-name'])})} data-placeholder='Last' hasValue={value && value.get('last-name')}>
            <Accessible>Last name</Accessible>
            <input {...this.inputProps('last', value, otherProps)} />
          </Placeholder>
        </div>
        <FieldErrors errors={errors.first() && errors.first().toList()} />
      </FieldSet>
    )
  }
}


Name.defaultProps = {
  data: Map(),
  errors: List(),
  showMiddleInitial: false,
  validator: noop,
  value: Map()
}

Name.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List),
  showMiddleInitial: PropTypes.bool,
  shouldShowErrors: PropTypes.bool,
  fieldType: PropTypes.string,
  validator: PropTypes.func,
  value: PropTypes.instanceOf(Map)
}

export default formFieldFactory(<Name />)
