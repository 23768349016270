/** @class ImageRadioList
 *
 * @desc
 * Forms v2 radio list variant which uses images for it inputs.
 *
 **/

import classnames from 'classnames'
import RadioList from './RadioList'

import './imageRadioList.scss'


const ImageRadioList = props => <RadioList {...props} className={classnames('image-radio-list', props.className)} />

export default ImageRadioList
