import {Component} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {Label} from './Labels'
import FieldSet from './FieldSet'
import formFieldFactory from '../formFieldFactory'
import FieldErrors from './FieldErrors'
import * as Links from '../../../Links'


export class ServiceTerms extends Component {
  value() { return this.refs.input.checked }

  render() {
    const {data, errors, ...otherProps} = this.props

    return (
      <FieldSet className='controls-group checkbox-form-group service-terms'>
        <Label errors={errors}>
          <input
            {...otherProps}
            name={data.get('name')}
            ref='input'
            type='checkbox'
            value={data.get('value')}
          />
          <span className='label-text'>I agree to the <Links.Terms target='_blank'>terms of service</Links.Terms> and <Links.Privacy target='_blank'>privacy policy</Links.Privacy></span>
          <FieldErrors errors={errors} />
        </Label>
      </FieldSet>
    )
  }
}

ServiceTerms.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List)
}

export default formFieldFactory(<ServiceTerms />)
