import PropTypes from 'prop-types'
import {Component} from 'react'
import {List, Map} from 'immutable'

import routerUtils from '../../../lib/routerUtils'

import {FORM_CONTEXT_TYPES} from './Form'


export default class FormField extends Component {
  constructor(props, context) {
    super(props, context)

    this.onChange = this.onChange.bind(this)
    this.updateDataAndErrors = this.updateDataAndErrors.bind(this)

    this.id = props.data.get('id')
  }

  UNSAFE_componentWillMount() { this.updateDataAndErrors() } // Initialize the Form state in case there is existing data in place. --BLR

  componentDidMount() {
    const {props} = this.props.children // Grab props passed into children - KAY

    // SelectField needs this to be called after component has mounted so as to
    // grab the default value from the field - KAY
    if (props.selectFormField)
      this.updateDataAndErrors()
  }

  onChange() {
    // Keep the Form state in sync whenever this component updates (BLR):
    this.updateDataAndErrors()

    // Do whatever the outside world needs me to:
    if (this.props.onChange)
      this.props.onChange()
  }

  formErrors(currentValue) {
    return this.props.validator(
      this.id,
      {[this.id]: Map.isMap(currentValue) ? currentValue.toJS() : currentValue}
    )
  }

  updateDataAndErrors() {
    let value = this.refs.formInput ? this.refs.formInput.value() : this.props.data.get('value')
    if (Map.isMap(value) && value.has(this.props.data.get('id')))
      value = value.get(this.props.data.get('id'))
    let data = this.props.data.set('value', value)
    if (!(data.has('group-id') && data.has('element-id'))) {
      data = data.set('group-id', List().concat(this.context.parentGroupId))
      data = data.set('element-id', this.props.element.get('id'))
    }

    this.context.updateDataAndErrors(
      this.id,
      data, // Always want to include all the data info in the data that will be sent off to the API. --BLR
      this.formErrors(value)
    )
  }

  render() {
    const dataData = this.context.data(this.id) // Check for data stored at the Form level. --BLR
    const data = dataData ? dataData.get('value') : this.props.data.get('value') // Fall back to initial value passed with the data if no data is present. --BLR
    const {children, ...props} = { // Used to avoid passing children prop as a prop to childrenWithProps because it can make things...strange. --BLR
      ...this.props,
      defaultValue: data,
      errors: this.context.shouldShowErrors() ? this.context.errors(this.id) : List(),
      onChange: this.onChange,
      ref: 'formInput',
      value: data
    }

    return (
      <span className='form-field'>
        {routerUtils.childrenWithProps(children, props)}
      </span>
    )
  }
}

FormField.contextTypes = FORM_CONTEXT_TYPES

FormField.defaultProps = {
  data: Map({id: ''}),
  element: Map({id: ''}),
  validator: () => List()
}

FormField.propTypes = {
  data: PropTypes.instanceOf(Map),
  element: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
  validator: PropTypes.func
}
