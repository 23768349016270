// I opted for this approach because many of the download libraries I found
// have so many dependencies and these few lines of code solves the same
// problem. This approach accommodates the different browser's behavior to
// trigger a download.

// The 'download' function takes the Base64 string of a file as 'fileData' and
// triggers the file to download.

// IE uses 'msSaveOrOpenBlob' to trigger a file download, and its the only
// browser that has  `window.navigator.msSaveBlob` as a valid property. If
// 'msSaveBlob' is detected (if the browser is IE), 'msSaveOrOpenBlob' is
// triggered along side the file name. This will trigger a download pop up.

// For other browsers, the way to trigger a download is to have a link with a
// download attribute on the page. When that link is clicked, the download is
// triggered. That is why I created an 'anchor', set the 'href' attribute to be
// the base64 string of the file data with some required header
// (data:application/octet-stream;base64;charset=utf-8), and I also set the
// download attribute to be the file name. If the file name is not set as the
// download attribute, the name of the file will always be 'download' without
// a file extension. The anchor is clicked to trigger the download pup up and
// removed afterwards. For Firefox, the anchor tag has to be appended to the
// page before it can be clicked, that is the reason for
// 'document.body.append(anchor)'. - Adebayo.

import mime from 'mime-types'
import {saveAs} from 'file-saver'

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  // Remove all white space to fix IE11 download issues. - KAY
  const byteCharacters = atob(b64Data.replace(/\s/g, ''))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++)
      byteNumbers[i] = slice.charCodeAt(i)

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}

export const download = ({fileData, fileName}) => {
  // Adding a fallback per docs here https://www.npmjs.com/package/mime-types
  const contentType = mime.contentType(fileName) || 'application/octet-stream'
  const blob = b64toBlob(fileData, contentType)
  // This handles download for all browsers. - Kay
  saveAs(blob, fileName)
}

export const downloadPlainData = ({fileData, fileName}) => {
  const blob = new Blob(
    fileData,
    // Adding a fallback per docs here https://www.npmjs.com/package/mime-types
    {type: mime.contentType(fileName) || 'text/plain;charset=utf-8'}
  )

  // This handles download for all browsers. - Kay
  saveAs(blob, fileName)
}

export const downloadWithAnchorTag = ({fileName, fileUrl, openOnNewTab = true}) => {
  const anchor = document.createElement('a')
  anchor.href = fileUrl

  if (openOnNewTab) {
    anchor.rel = 'noopener noreferrer'
    anchor.target = '_blank'
  }

  document.body.appendChild(anchor)

  anchor.setAttribute('download', fileName)
  anchor.click()
  document.body.removeChild(anchor)
}
