import PropTypes from 'prop-types'

import {IconButton, Stack, Typography, styled} from '@mui/material'

import Button from '../../button/Button'
import CloseIcon from '../../../core/svg_icons/icons/CloseIcon'
import StyledSmallBox from '../../boxes/StyledSmallBox'
import warningIcon from '../../../../shared_assets/icons/warning-icon.svg'
import StyledBaseModal from '../StyledBaseModal'

const StyledConfirmationModalContent = styled(StyledSmallBox)(
  () => `
    align-items: center;
    font-feature-settings: 'liga' off, 'clig' off;
    margin: 134px auto 0;
    .button-group {
      flex-wrap: wrap-reverse;
      justify-content: center;
      margin-top: 24px;
    }
  `
)

const StyledH2Typography = styled(Typography)(
  ({theme, color}) => `
    &.heading {
      border-bottom: none;
      color: ${theme.palette[color].main};
      font-size: 1.3125rem;
      font-weight: 600;
      line-height: 109.524%;
      margin: 12px 0 0;
      text-align: center;
    }
  `
)

const StyledBodyTypography = styled(Typography)(
  ({theme}) => `
    &.body-text {
      line-height: 106.667%;
      margin: 16px 0 0;
    }
    color: ${theme.palette.primary.bodyText};
    font-size: 0.9375rem;
    font-weight: 300;
  `
)

const StyledConfirmationModal = props => {
  const {
    additionalElement,
    bodyText,
    cancelButtonText,
    confirmButtonText,
    colorVariant,
    headingText,
    loading,
    onCancel,
    onClose,
    onConfirmation,
    showWarningIcon,
    ...otherProps
  } = props

  return (
    <StyledBaseModal
      {...otherProps}
      dataTestId='confirmation-modal'
      onClose={onClose}>
      <StyledConfirmationModalContent>
        <IconButton
          data-testid='close-button'
          disabled={loading}
          onClick={onClose}
          style={{
            alignSelf: 'flex-end',
            cursor: loading ? 'not-allowed' : 'pointer',
            padding: 0
          }}>
          <CloseIcon
            data-testid='close-icon'
            height='12'
            width='12'
          />
        </IconButton>
        {
          showWarningIcon &&
          <img
            alt='warning icon'
            src={warningIcon}
            style={{margin: '12px 0'}}
          />
        }
        <StyledH2Typography
          className='heading'
          color={colorVariant}
          data-testid='heading'
          variant='h2'>
          {headingText}
        </StyledH2Typography>
        {
          bodyText &&
          <StyledBodyTypography
            className='body-text'
            textAlign='center'
            variant='body1'>
            {bodyText}
          </StyledBodyTypography>
        }
        {additionalElement}
        <Stack
          aria-label='Action buttons'
          className='button-group'
          direction='row'
          gap={2}
          role='group'>
          <Button
            color='cancel'
            data-testid='cancel-button'
            disabled={loading}
            onClick={onCancel || onClose}>
            {cancelButtonText}
          </Button>
          <Button
            color={colorVariant}
            data-testid='confirm-button'
            loading={loading}
            onClick={onConfirmation}>
            {confirmButtonText}
          </Button>
        </Stack>
      </StyledConfirmationModalContent>
    </StyledBaseModal>
  )
}

StyledConfirmationModal.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Exit',
  colorVariant: 'error'
}

StyledConfirmationModal.propTypes = {
  additionalElement: PropTypes.node,
  bodyText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  colorVariant: PropTypes.oneOf(['error', 'primary']),
  headingText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showWarningIcon: PropTypes.bool
}

export default StyledConfirmationModal
