import {Component} from 'react'
import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'

import Select from './Select'
import USStatesData from '../../../../lib/us-states-data.json'


export default class State extends Component {
  constructor() {
    super()
    this.itemList = this.itemList()
  }

  itemList() {
    return (
      fromJS(USStatesData).map(name => Map({label: name, value: name}))
        .unshift(Map({label: 'Select a state', value: ''}))
    )
  }

  render() {
    const {data, ...otherProps} = this.props

    return (
      <Select
        {...otherProps}
        data={data.set('items', this.itemList)}
      />
    )
  }
}


State.propTypes = {
  data: PropTypes.instanceOf(Map)
}
