/*
  Note: some field names collide with global objects (e.g., Date) so be careful when importing them outside of this index
  since you then won't be able to use those globals. --BLR
*/
import Address from './Address'
import Checkbox from './Checkbox'
import CheckboxList from './CheckboxList'
import Date from './Date'
import Email from './Email'
import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import File from './File'
import HorizontalRadioList from './HorizontalRadioList'
import ImageRadioList from './ImageRadioList'
import InlineRadioList from './InlineRadioList'
import Input from './Input'
import * as Labels from './Labels'
import Name from './Name'
import Number from './Number'
import Password from './Password'
import RadioButton from './RadioButton'
import RadioList from './RadioList'
import Select from './Select'
import ServiceTerms from './ServiceTerms'
import SimpleCheckbox from './SimpleCheckbox'
import State from './State'
import Tel from './Tel'
import Text from './Text'
import TextArea from './TextArea'
import Url from './Url'
import VerticalRadioList from './VerticalRadioList'
import ZipOrCountry from './ZipOrCountry'

export default {
  Address,
  Checkbox,
  CheckboxList,
  Date,
  Email,
  FieldErrors,
  FieldSet,
  File,
  HorizontalRadioList,
  ImageRadioList,
  InlineRadioList,
  Input,
  Labels,
  Name,
  Number,
  Password,
  RadioButton,
  RadioList,
  Select,
  ServiceTerms,
  SimpleCheckbox,
  State,
  Tel,
  Text,
  TextArea,
  Url,
  VerticalRadioList,
  ZipOrCountry
}
