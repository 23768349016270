import classnames from 'classnames'
import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import Checkbox from './Checkbox'

import formFieldFactory from '../formFieldFactory'


export class CheckboxList extends React.Component {
  value() {
    return this.props.data.get('items').map(
      item => (this.refs[item.get('value')].checked() ? item.get('value') : null)
    ).filter(Boolean)
  }

  render() {
    const {data, errors, ...otherProps} = this.props
    const legend = data.get('legend')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className={classnames('controls-flexbox flex-container checkbox-form-group', {error: errors && errors.size})}>
          {
            data.get('items').map(
              item => (
                <Checkbox
                  {...otherProps}
                  defaultChecked={data.get('value') && data.get('value').includes(item.get('value'))}
                  item={item}
                  key={item.get('label')}
                  name={data.get('name')}
                  ref={item.get('value')}
                />
              )
            )
          }
        </div>
        <FieldErrors errors={errors} />
      </FieldSet>
    )
  }
}

CheckboxList.defaultProps = {
  data: fromJS({
    legend: '',
    name: '',
    value: []
  }),
  errors: List()
}

CheckboxList.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List)
}

export default formFieldFactory(<CheckboxList />)
