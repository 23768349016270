import PropTypes from 'prop-types'

import {Modal} from '@mui/material'

const StyledBaseModal = ({children, className, dataTestId, onClose, open}) => (
  <Modal
    className={className}
    data-testid={dataTestId}
    disablePortal
    onClose={reason => (reason === 'backdropClick' ? null : onClose)}
    open={open}
    sx={{
      overflowX: 'hidden'
    }}>
    {children}
  </Modal>
)

StyledBaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default StyledBaseModal
