import PropTypes from 'prop-types'
import {useState} from 'react'

import ConfirmationModal from '../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import DeleteIcon from '../../../../shared_assets/v2/DeleteIcon'

const RemoveFile = ({id, isViewMode, removeFile, name, virusFound}) => {
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleDeleteIconClick = () => {
    virusFound ? removeFile(id) : setShowModal(true)
  }

  const deleteFile = () => {
    setProcessing(true)
    removeFile(id)
  }

  if (showModal) {
    return (
      <ConfirmationModal
        bodyText='You will not be able to recover this file.'
        confirmButtonText='Delete'
        headingText='Delete this file?'
        loading={processing}
        onClose={() => setShowModal(false)}
        onConfirmation={deleteFile}
        open={true}
      />
    )
  } else {
    return (
      !isViewMode &&
      <DeleteIcon
        alt={`Delete ${name}`}
        id={id}
        onClick={handleDeleteIconClick}
        className='delete-file'
      />
    )
  }
}

RemoveFile.propTypes = {
  id: PropTypes.string,
  isViewMode: PropTypes.bool,
  name: PropTypes.string,
  removeFile: PropTypes.func,
  virusFound: PropTypes.bool
}

export default RemoveFile

