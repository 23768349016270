import classnames from 'classnames'
import PropTypes from 'prop-types'

import Closer from '../../../../shared_components/core/closer/Closer'
import * as ContentBoxes from './ContentBoxes'

import './boxes.scss'


export const UnwrappedBox = ({ContentBoxType, ...otherProps}) => (
  <ContentBoxType {...otherProps} className={classnames('box', otherProps.className)}>
    {otherProps.closer && <div className='box-lid flex-container'><Closer alt='dismiss' className='box-closer flex-child' closer={otherProps.closer} /></div>}
    {otherProps.children}
  </ContentBoxType>
)

UnwrappedBox.defaultProps = {ContentBoxType: ContentBoxes.ContentBox}

UnwrappedBox.propTypes = {
  closer: PropTypes.func,
  ContentBoxType: PropTypes.func
}


export const SHARED_BOX_PROP_TYPES = {
  wrap: PropTypes.bool,
  wrapperClassName: PropTypes.string
}

export const Box = ({wrap, wrapperClassName, ...otherProps}) => {
  const unwrapped = <UnwrappedBox {...otherProps} ContentBoxType={ContentBoxes.ContentBox} />
  return (
    wrap ?
      <div className={classnames('box-wrapper', wrapperClassName)}>{unwrapped}</div> :
      unwrapped
  )
}

Box.propTypes = SHARED_BOX_PROP_TYPES

export const BoxGutterSmall = ({wrap, wrapperClassName, ...otherProps}) => {
  const unwrapped = <UnwrappedBox {...otherProps} ContentBoxType={ContentBoxes.ContentBoxGutterSmall} />
  return (
    wrap ?
      <div className={classnames('box-wrapper', wrapperClassName)}>{unwrapped}</div> :
      unwrapped
  )
}

BoxGutterSmall.propTypes = SHARED_BOX_PROP_TYPES

export const BoxGutterMedium = ({wrap, wrapperClassName, ...otherProps}) => {
  const unwrapped = <UnwrappedBox {...otherProps} ContentBoxType={ContentBoxes.ContentBoxGutterMedium} />
  return (
    wrap ?
      <div className={classnames('box-wrapper', wrapperClassName)}>{unwrapped}</div> :
      unwrapped
  )
}

BoxGutterMedium.propTypes = SHARED_BOX_PROP_TYPES

export const BoxGutterLarge = ({wrap, wrapperClassName, ...otherProps}) => {
  const unwrapped = <UnwrappedBox {...otherProps} ContentBoxType={ContentBoxes.ContentBoxGutterLarge} />
  return (
    wrap ?
      <div className={classnames('box-wrapper', wrapperClassName)}>{unwrapped}</div> :
      unwrapped
  )
}

BoxGutterLarge.propTypes = SHARED_BOX_PROP_TYPES

export const BoxGutterExtraLarge = ({wrap, wrapperClassName, ...otherProps}) => {
  const unwrapped = <UnwrappedBox {...otherProps} ContentBoxType={ContentBoxes.ContentBoxGutterExtraLarge} />
  return (
    wrap ?
      <div className={classnames('box-wrapper', wrapperClassName)}>{unwrapped}</div> :
      unwrapped
  )
}

BoxGutterExtraLarge.propTypes = SHARED_BOX_PROP_TYPES
