import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {fileExtension} from '../lib/tools'

import './fileIcon.scss'

const FILE_EXTENSION_MAP = Map({
  csv: 'csv',
  doc: 'doc',
  docx: 'doc',
  gif: 'img',
  jpeg: 'img',
  jpg: 'img',
  mp3: 'mp3',
  pdf: 'pdf',
  png: 'img',
  ppt: 'ppt',
  pptx: 'ppt',
  svg: 'img',
  txt: 'doc',
  xls: 'xls',
  xlsx: 'xls',
  zip: 'zip'
})

const FileIcon = ({fileName}) => (
  <div className='file-icon'>
    <div className='file-extension'>
      {FILE_EXTENSION_MAP.get(fileExtension(fileName), 'N/A')}
    </div>
  </div>
)

FileIcon.propTypes = {
  fileName: PropTypes.string
}

export default FileIcon
