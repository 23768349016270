import classnames from 'classnames'
import PropTypes from 'prop-types'

import {forwardRef} from 'react'

import ButtonLoadingSpinner from './ButtonLoadingSpinner'
import StyledButton from './StyledButton'
import StyledButtonText from './StyledButtonText'

// eslint-disable-next-line react/display-name
const Button = forwardRef((props, ref) => {
  const {loading, ...otherProps} = props

  return (
    <StyledButton
      {...otherProps}
      className={classnames('button', otherProps.className)}
      disabled={otherProps.disabled || loading}
      ref={ref}
      size='large'
      variant='contained'>
      <StyledButtonText loading={loading} variant='button'>
        {otherProps.children}
      </StyledButtonText>
      {
        loading &&
        <ButtonLoadingSpinner data-testid='button-loading-spinner' size={20} />
      }
    </StyledButton>
  )
})

Button.defaultProps = {
  color: 'primary'
}

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

export default Button
