import classnames from 'classnames'

import './contentBoxes.scss'


export const ContentBox = props => <article {...props} className={classnames('content-box core', props.className)} />

export const ContentBoxGutterSmall = props => <ContentBox {...props} className={classnames('gutter-small', props.className)} />

export const ContentBoxGutterMedium = props => <ContentBox {...props} className={classnames('gutter-medium', props.className)} />

export const ContentBoxGutterLarge = props => <ContentBox {...props} className={classnames('gutter-large', props.className)} />

export const ContentBoxGutterExtraLarge = props => <ContentBox {...props} className={classnames('gutter-extra-large', props.className)} />
