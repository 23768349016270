import {Box, styled} from '@mui/material'

import colors from '../../../dashboard/styles/abstracts/colors.module.scss'

const StyledBaseBox = styled(Box)(
  () => `
    border-radius: 20px;
    background: ${colors.boxBackground};
    display: flex;
    flex-direction: column;
    outline: 0;
    width: 90%;
  `
)

export default StyledBaseBox
