import classnames from 'classnames'


export const Accessible = props => {
  if (props.children)
    return <span {...props} className={classnames('label-text', 'visually-hidden', props.className)} />

  return <noscript />
}

export const Label = props => <label {...props} className={classnames('flex-child', props.className, {error: props.errors && props.errors.size})} />

export const Placeholder = ({className, ...otherProps}) => (
  <Label
    className={
      classnames(
        'placeholder',
        className,
        {
          'has-focus-or-value': otherProps.hasValue // Ensure that items with default values don't put the values on top of the placeholder text. --BLR
        }
      )
    }
    {...otherProps}
  />
)

export const PlaceholderHideFocus = props => <Placeholder {...props} className={classnames('placeholder-hide-focus', props.className)} />
