import classnames from 'classnames'
import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import FieldSet from './FieldSet'
import {Placeholder} from './Labels'

import {countries as COUNTRIES} from '../lib/countries.json'
import States from '../../../../lib/us-states-data.json'
import formFieldFactory from '../formFieldFactory'
import {onBlur, onFocus} from '../../../../dev_only/forms_playground/lib/tools'

export class Address extends Component {
  value() {
    const addressInputs = {
      'address-line-1': this.refs['address-line-1'].value,
      'address-line-2': this.refs['address-line-2'].value,
      city: this.refs.city.value,
      country: this.refs.country[this.refs.country.selectedIndex].value,
      region: this.refs.region.value,
      'postal-code': this.refs['postal-code'].value
    }

    return Map(addressInputs).filter(inputs => inputs !== '')
  }

  render() {
    const {data, errors, onChange} = this.props
    const errorsClass = classnames({error: errors && errors.size})
    const legend = data.get('legend')
    const value = data.get('value') || Map()
    const selectedCountry = this.props.defaultValue && this.props.defaultValue.get('country')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className='address-field-group controls-flexbox flex-container'>
          <label className={classnames('flex-child select', {error: errors && errors.size})}>
            <span className='label-text visually-hidden'>Select country</span>
            <select defaultValue={value.get('country')} name='country' ref='country' className='select-option-name' onChange={onChange}>
              <option value=''>Select country</option>
              <option value='United States'>United States</option>
              <option value='' disabled>---</option>
              {COUNTRIES.map(country => <option key={country.get('value')} value={country.get('label')}>{country.get('label')}</option>)}
            </select>
          </label>
          <Placeholder hasValue={value.get('address-line-1')} className={classnames('address-line', errorsClass)} data-placeholder='Address line 1'>
            <span className='label-text visually-hidden'>Address line 1</span>
            <input
              name='address-line-1'
              ref='address-line-1'
              defaultValue={value.get('address-line-1')}
              onChange={onChange}
              type='text'
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Placeholder>
          <Placeholder hasValue={value.get('address-line-2')} className={classnames('address-line', errorsClass)} data-placeholder='Address line 2'>
            <span className='label-text visually-hidden'>Address line 2</span>
            <input
              defaultValue={value.get('address-line-2')}
              name='address-line-2'
              ref='address-line-2'
              type='text'
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Placeholder>
          <Placeholder hasValue={value.get('city')} className={errorsClass} data-placeholder='City'>
            <span className='label-text visually-hidden'>City</span>
            <input
              defaultValue={value.get('city')}
              name='city'
              ref='city'
              type='text'
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Placeholder>
          {
            selectedCountry === 'United States' ?
              <label className={classnames('flex-child select', {error: errors && errors.size})}>
                <span className='label-text visually-hidden'>Select state</span>
                <select defaultValue={value.get('region')} name='region' ref='region' className='select-option-name' onChange={onChange}>
                  <option value=''>Select state</option>
                  {States.map(state => <option key={state} value={state}>{state}</option>)}
                </select>
              </label> :
              <Placeholder hasValue={value.get('region')} className={errorsClass} data-placeholder='State/Province/Region'>
                <span className='label-text visually-hidden'>State/Province/Region</span>
                <input
                  name='region'
                  ref='region'
                  defaultValue={value.get('region')}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type='text'
                />
              </Placeholder>
          }
          <Placeholder hasValue={value.get('postal-code')} className={errorsClass} data-placeholder='ZIP/Postal code'>
            <span className='label-text visually-hidden'>ZIP/Postal code</span>
            <input
              defaultValue={value.get('postal-code')}
              name='postal-code'
              ref='postal-code'
              type='text'
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Placeholder>
        </div>
      </FieldSet>
    )
  }
}

Address.propTypes = {
  data: PropTypes.instanceOf(Map),
  defaultValue: PropTypes.instanceOf(Map),
  errors: PropTypes.object,
  onChange: PropTypes.func
}


export default formFieldFactory(<Address />)

