import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {Component} from 'react'

import {Label} from './Labels'


export default class RadioButton extends Component { // Must be a class because it needs to have a ref attached to it and have a child ref too. --BLR
  checked() { return this.refs.input.checked } // Helper for parent to grab checked status of the input. --BLR

  render() {
    const {item, image, ...otherProps} = this.props

    return (
      <Label>
        <input ref='input' type='radio' disabled={item.get('disabled')} {...otherProps} />
        <span className='label-text'>
          {image && <img src={image} alt={item.get('label')} />}
          <div>{item.get('label')}</div>
        </span>
      </Label>
    )
  }
}

RadioButton.propTypes = {
  image: PropTypes.string,
  item: PropTypes.instanceOf(Map),
  name: PropTypes.string
}
