import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import {Accessible, PlaceholderHideFocus} from './Labels'
import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'

import formFieldFactory from '../formFieldFactory'


class TextArea extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.errors !== this.props.errors ||
      nextProps.value !== this.props.value ||
      nextProps.shouldShowErrors !== this.props.shouldShowErrors
    )
  }

  value() { return this.refs.formInput.value }

  render() {
    const {data, errors, ...otherProps} = this.props
    const legend = data.get('legend')
    const placeholder = data.get('placeholder')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className='controls-flexbox flex-container'>
          <PlaceholderHideFocus hasValue={otherProps.value || data.get('value')} data-placeholder={placeholder} errors={errors}>
            <Accessible>{legend}</Accessible>
            <textarea name={data.get('name')} ref='formInput' {...otherProps} />
            <FieldErrors errors={errors} />
          </PlaceholderHideFocus>
        </div>
      </FieldSet>
    )
  }
}

TextArea.defaultProps = {
  data: fromJS({
    legend: '',
    name: '',
    placeholder: ''
  }),
  errors: List()
}

TextArea.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List)
}

export default formFieldFactory(<TextArea />)
