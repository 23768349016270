import {Map} from 'immutable'
import PropTypes from 'prop-types'

import FormField from './FormField'
import withMaskField from './withMaskField'

import {ensureMap} from './lib/tools'


const formFieldFactory = children => {
  const NewFormField = props => (
    <FormField
      children={children}
      {...props}
      data={ensureMap(props.data)}
    />
  )

  NewFormField.propTypes = {data: PropTypes.oneOfType([Map, PropTypes.object])}

  return withMaskField(NewFormField)
}

export default formFieldFactory
