import {Component} from 'react'

import formFieldFactory from '../formFieldFactory'
import Input from './Input'
import {trimString} from '../../../../lib/textTools'


class Email extends Component {
  value() { return trimString(this.refs.formInput.value()) }

  render() { return <Input {...this.props} ref='formInput' type='email' /> }
}

export default formFieldFactory(<Email />)
