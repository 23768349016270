/** @function withMaskField
 *
 * @desc
 * This is an HOC that handles the functionality to hide and show text in an
 * input field with type 'text', 'email', 'url', 'number', etc.
 */

import {useState} from 'react'
import {PropTypes} from 'prop-types'

import showInputIcon from '../../../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../../../shared_assets/v2/mask-icon.svg'

import {isInternetExplorer11} from '../../../lib/tools'
import Logger from '../../../lib/NewLogger'

import './withMaskField.scss'


const withMaskField = FormField => {
  const FieldWithMask = props => {
    if (props.maskText) {
      let isProcessingEventPropagation = false
      const [hideMask, toggleMask] = useState(!!props.data.get('value'))

      const toggleMaskAndLogEvent = () => {
        Logger.log({
          name: hideMask ? 'clicked_unmask_field' : 'clicked_mask_field',
          payload: {
            type: props.eventType,
            context: props.context,
            item: props.itemName,
            wildcard: props.itemName,
            header: props.nextBestActionHeader
          }
        })
        toggleMask(!hideMask)
      }

      const onFocus = event => {
        const element = event.target

        if (element.hasAttribute('readonly')) {
          // This is needed to be set because IE11 calls blur() asynchronously
          // which in turn sees that readonly attribute has been removed.
          // It helps us prevent entering the condition in the onBlur when blur()
          // is called since element.hasAttribute('readonly') will be true because
          // it is run asynchronously.
          isProcessingEventPropagation = true
          // iOS and IE need this before a change of the readOnly attribute
          element.blur()

          if (isInternetExplorer11()) {
            element.removeAttribute('readonly')
            // This is needed because of the weird way IE11 works.
            // Ref: https://stackoverflow.com/a/2600261. - Kay
            setTimeout(
              () => {
                element.focus()
                isProcessingEventPropagation = false
              },
              10
            )
          } else {
            element.removeAttribute('readonly')
            element.focus()
            isProcessingEventPropagation = false
          }
        }
      }

      const onBlur = event => {
        const element = event.target

        if (!element.hasAttribute('readonly') && !isProcessingEventPropagation) {
          element.setAttribute('readonly', true)
          // Mask field 2s after the user has finished typing and there is value in the field.
          setTimeout(() => element.value && element.hasAttribute('readonly') && toggleMask(true), 2000)
        }
      }

      return (
        <div className='with-mask-field'>
          <FormField
            {...props}
            {...(hideMask && {fieldType: 'password'})}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={true}
          />
          <span className='mask-icon-and-text' onClick={toggleMaskAndLogEvent}>
            <img alt='password show/hide icon' className='mask-icon' src={hideMask ? showInputIcon : hideInputIcon} />
            {hideMask ? 'Show' : 'Hide'}
          </span>
        </div>
      )
    } else {
      return <FormField {...props} />
    }
  }

  FieldWithMask.defaultProps = {maskText: false}

  FieldWithMask.propTypes = {
    context: PropTypes.string,
    eventType: PropTypes.string,
    itemName: PropTypes.string,
    maskText: PropTypes.bool,
    nextBestActionHeader: PropTypes.string
  }

  return FieldWithMask
}

export default withMaskField
