import PropTypes from 'prop-types'

import spinnerIcon from '../../../../shared_assets/spinner.svg'
import {pluralize} from '../../../../lib/tools'

import './fileResponseMigrationDetails.scss'


const FileResponseMigrationDetails = ({count}) => (
  <div className='file-response-migration-details flex-container'>
    <img src={spinnerIcon} alt='spinner' />
    <span>Encrypting {count} {pluralize('file', count)}</span>
  </div>
)

FileResponseMigrationDetails.propTypes = {
  count: PropTypes.number
}


export default FileResponseMigrationDetails
