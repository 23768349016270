import {fromJS, Map} from 'immutable'

import apiV2 from '../lib/apiV2'
import {cleanResponse} from '../lib/apiHelpers'
import AppDispatcher from '../lib/ep-dispatcher'
import superagent from 'superagent'
import extra_storage from '../lib/extra_storage'
import TestPrep from '../lib/TestPrep'
import {download} from '../lib/fileDownload'

const actionTypes = [
  'DID_FINISH_DECRYPTION',
  'DID_START_DECRYPTION',
  'DID_FINISH_ENCRYPTION',
  'DID_START_ENCRYPTION',
  'DID_UPLOAD_FILE',
  'GOT_FILE_UPLOAD_STATUS'
]

const pollingJobs = {}
const FileUploadActions = {
  ...TestPrep,
  Types: actionTypes.reduce((types, type) => ({...types, [type]: type}), {}),
  /* The request API call is not JSON-API compliant because we are uploading multi-part files instead
  sending a base64 string as part of a JSON request. This is so our file uploads are faster since streaming a base64 string adds
  to the size of the file being uploaded */
  uploadToServer(file) {
    superagent.post(`${API_HOST}/api/v2/uploads`)
      .set('Authorization', `Token token=${extra_storage.getItem('authToken')}`)
      .send(file)
      .end((error, response) => {
        const formattedResponse = fromJS(response.body)
        const data = error ? {errors: ['Upload failed']} : formattedResponse
        AppDispatcher.dispatch({
          actionType: FileUploadActions.Types.DID_UPLOAD_FILE,
          data
        })

        if (!error) {
          const id = formattedResponse.getIn(['upload', 'id'])
          pollingJobs[id] = setInterval(() => FileUploadActions.getEncryptionStatus(id), 2000)
        }
      })
  },

  getEncryptionStatus(id) {
    apiV2.GET({
      onSuccess: response => {
        const formattedResponse = cleanResponse(response.body.data)
        const responseWithUpload = formattedResponse.set('upload', formattedResponse.get('attributes').set('id', formattedResponse.get('id')))
        const status = responseWithUpload.getIn(['upload', 'status'])

        AppDispatcher.dispatch({
          actionType: FileUploadActions.Types.GOT_FILE_UPLOAD_STATUS,
          data: responseWithUpload
        })

        if (status === 'encrypted' || status === 'virus_detected')
          clearInterval(pollingJobs[formattedResponse.get('id')])


        if (status === 'encrypting') {
          AppDispatcher.dispatch({
            actionType: FileUploadActions.Types.DID_START_ENCRYPTION,
            data: Map({fileStatus: 'Encrypting', id: formattedResponse.get('id'), name: responseWithUpload.getIn(['upload', 'filename'])})
          })
        }

        if (status === 'encrypted') {
          AppDispatcher.dispatch({
            actionType: FileUploadActions.Types.DID_FINISH_ENCRYPTION,
            data: Map({fileStatus: 'Encrypted', id: formattedResponse.get('id'), name: responseWithUpload.getIn(['upload', 'filename'])})
          })
        }
      },
      id,
      resource: 'uploads'
    })
  },

  downloadFile(id, filename) {
    AppDispatcher.dispatch({
      actionType: FileUploadActions.Types.DID_START_DECRYPTION,
      data: Map({fileStatus: 'Decrypting', id, name: filename})
    })

    apiV2.GET({
      id: `${id}?download=true`,
      onSuccess: response => {
        const formattedResponse = cleanResponse(response.body.data)
        const responseWithUpload = formattedResponse.set('upload', formattedResponse.get('attributes'))

        AppDispatcher.dispatch({
          actionType: FileUploadActions.Types.DID_FINISH_DECRYPTION,
          data: Map({fileStatus: 'Decrypted', id: formattedResponse.get('id'), name: responseWithUpload.getIn(['upload', 'filename'])})
        })

        download({fileData: formattedResponse.get('decryptedFile'), fileName: responseWithUpload.getIn(['upload', 'filename'])})
      },
      resource: 'uploads'
    })
  }
}

export default FileUploadActions
