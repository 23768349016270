import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'
import * as Labels from './Labels'


export class Input extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.errors !== this.props.errors ||
      nextProps.value !== this.props.value ||
      nextProps.shouldShowErrors !== this.props.shouldShowErrors ||
      nextProps.fieldType !== this.props.fieldType
    )
  }

  value() { return this.refs.formInput.value }

  render() {
    const {data, errors, fieldType, ...otherProps} = this.props
    const legend = data.get('legend')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className='controls-flexbox flex-container'>
          <Labels.Label errors={errors}>
            <Labels.Accessible>{legend}</Labels.Accessible>
            <input
              name={data.get('name')}
              placeholder={data.get('placeholder')}
              ref='formInput'
              {...otherProps}
              type={
                // fieldType basically comes from withMaskField and it's being
                // checked first before the type passed by any component using
                // this is used. - Kay
                fieldType || this.props.type
              }
            />
            <FieldErrors errors={errors} />
          </Labels.Label>
        </div>
      </FieldSet>
    )
  }
}

Input.defaultProps = {
  data: fromJS({
    legend: '',
    name: ''
  }),
  errors: List(),
  type: 'text'
}

Input.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List),
  fieldType: PropTypes.string,
  shouldShowErrors: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string
}

export default Input
