import {styled} from '@mui/material'

import StyledBaseBox from './StyledBaseBox'
import breakpoints from '../../../dashboard/styles/abstracts/breakpoints.module.scss'

const StyledSmallBox = styled(StyledBaseBox)(
  () => `
    max-width: 480px;
    padding: 32px;
    @media (min-width: ${breakpoints.mobileMd}) {
      padding: 40px;
    }
  `
)

export default StyledSmallBox
