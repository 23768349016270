import classnames from 'classnames'
import {Component} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import FieldSet from './FieldSet'
import {Placeholder} from './Labels'
import FieldErrors from './FieldErrors'

import {countries as COUNTRIES} from '../lib/countries.json'
import formFieldFactory from '../formFieldFactory'
import {onBlur, onFocus} from '../../../../dev_only/forms_playground/lib/tools'
import {filterSanctionedCountries} from '../../../../lib/tools'

class ZipOrCountry extends Component {
  constructor(props) {
    super(props)

    const defaultValue = props.data.get('value', Map())
    this.state = {showZip: !defaultValue.get('country-code')}
    this.toggleZip = this.toggleZip.bind(this)
  }

  value() {
    const zipInputs = {
      zip: this.refs.zip ? this.refs.zip.value : null,
      'country-code': this.refs['country-code'] ? this.refs['country-code'].value : null
    }

    return Map(zipInputs).filter(inputs => inputs)
  }

  // filters sanctioned countries if this component is used user upgrade situatuions like JICL
  countryList() {
    if (this.props.upgrade)
      return filterSanctionedCountries(COUNTRIES)
    else
      return COUNTRIES
  }

  toggleZip() {
    this.setState(prevState => ({...prevState, showZip: !prevState.showZip}), this.props.onChange)
  }

  render() {
    const {data, errors, value, onChange} = this.props
    const legend = data.get('legend')
    const dataValue = value || Map()

    return (
      <FieldSet className='zip-or-country-field-group controls-group'>
        {legend && <legend>{legend}</legend>}
        <div className='controls-flexbox flex-container'>
          {
            !this.state.showZip &&
              (
                <label className={classnames('flex-child select', {error: errors && errors.size})}>
                  <span className='label-text visually-hidden'>Select country</span>
                  <select defaultValue={dataValue.get('country-code')} name='country-code' ref='country-code' className='select-option-name' onChange={onChange}>
                    <option value=''>Select country</option>
                    <option value='US'>United States</option>
                    <option value='' disabled>---</option>
                    {this.countryList().map(country => <option key={country.get('value')} value={country.get('value')}>{country.get('label')}</option>)}
                  </select>
                </label>
              )
          }
          {
            this.state.showZip &&
              (
                <Placeholder hasValue={dataValue.get('zip')} className={classnames({error: errors && errors.size})} data-placeholder='ZIP/Postal code'>
                  <span className='label-text visually-hidden'>ZIP code</span>
                  <input
                    defaultValue={dataValue.get('zip')}
                    name='zip'
                    ref='zip'
                    type='text'
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                </Placeholder>
              )
          }
        </div>
        <a className='zip-toggle' onClick={this.toggleZip}>I {this.state.showZip ? "don't live" : 'live'} in the United States</a>
        <FieldErrors errors={errors} />
      </FieldSet>
    )
  }
}

ZipOrCountry.propTypes = {
  data: PropTypes.instanceOf(Map),
  value: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List),
  upgrade: PropTypes.bool,
  onChange: PropTypes.func
}


export default formFieldFactory(<ZipOrCountry />)

