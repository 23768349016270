/** @class Flash
 *
 * @deprecated Do not use this in new code. Use the newer approach in
 *   +shared_components/notifications/NotificationContext.jsx+ instead.
 * @desc
 * Legacy component to show flash messages.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'
import './flash.scss'


export default class Flash extends Component {
  componentWillUnmount() {
    if (this.props.closer)
      this.props.closer()
  }

  render() {
    if (this.props.flash || this.props.children) {
      return (
        <div className='flash'>
          <div className='msg'>
            {this.props.flash}
            {this.props.children}
          </div>
          {
            this.props.closer &&
            <div className='close' onClick={this.props.closer}><a>X</a></div>
          }
        </div>
      )
    } else {
      return <div />
    }
  }
}

Flash.defaultProps = {
  hasClose: true
}

Flash.propTypes = {
  closer: PropTypes.func,
  flash: PropTypes.node,
  hasClose: PropTypes.bool
}
