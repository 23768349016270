/** @module fileTools
 *
 * @desc
 * Helpers related to the v2 `File` component that handle some of the data
 * munging so at least that logic is tested since the component itself is
 * not tested and it's difficult to figure out how to do that at this point.
 */

/**
 * Modifies the given `uploadedFile` with the correct status and ID while the virus scanning
 * is going on. Note that the ID is only set the first time as the `response` does not have
 * the correct ID in subsequent pings checking the status.
 *
 * @param {String} status - the current status of the file upload as reported by the API ping.
 *
 * @returns {Boolean} `true` if virus scanning is ongoing, `false` if it has not begun or is complete
 */
export const isScanningForVirus = status => (status === 'uploaded' || status === 'scanning')

/**
 * Modifies the given `uploadedFile` with the correct status and ID while the virus scanning
 * is going on. Note that the ID is only set the first time as the `response` does not have
 * the correct ID in subsequent pings checking the status.
 *
 * @param {Map} response - the result of the API call checking on the status of the file upload.
 * @param {Map} uploadedFile - the current shape of the upload in the `File` form field's state.
 *
 * @returns {Map} the updated shape of the upload to be stored in the `File` form field's state
 *    until another API ping comes back.
 */
export const virusScanningStatus = (response, uploadedFile) => (
  uploadedFile
    .set('uploadStatus', 'Scanning for virus')
    .set(
      'id',
      // Don't stomp on the `id` if it already exists as this prevents saving of any responses
      // in the form:
      uploadedFile.get('id') ? uploadedFile.get('id') : response.get('id')
    )
)
