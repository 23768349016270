/*
  Note: the field name Date collides with a global object so be careful when importing this independently of the index. --BLR
*/

import {Component} from 'react'

import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import FieldSet from './FieldSet'
import FieldErrors from './FieldErrors'
import {Placeholder} from './Labels'

import formFieldFactory from '../formFieldFactory'
import {onBlur, onFocus} from '../../../../dev_only/forms_playground/lib/tools'
import {dateFieldValuesForForm} from '../../../../lib/dateTools'

import './date.scss'

const PLACEHOLDERS = {
  day: ['DD', 'Day'],
  month: ['MM', 'Month'],
  year: ['YYYY', 'Year']
}


class Date extends Component {
  shouldComponentUpdate(nextProps) {
    // When the field is fully empty there is no object for these two so just adding a fallback:
    const oldValue = this.props.value || Map()
    const newValue = nextProps.value || Map()

    return (
      nextProps.errors !== this.props.errors ||
      nextProps.shouldShowErrors !== this.props.shouldShowErrors ||
      !newValue.equals(oldValue)
    )
  }

  value() {
    return Map({
      day: this.refs.day.value,
      month: this.refs.month.value,
      year: this.refs.year.value
    }).filter(inputs => inputs !== '')
  }

  errorsClass(fieldValue) {
    return classnames({error: fieldValue && this.props.errors && this.props.errors.size})
  }

  placeholder(fieldValue, field) {
    if (field)
      return PLACEHOLDERS[field][fieldValue ? 1 : 0]
    else
      return ''
  }

  fieldProps(value, field) {
    return {
      onChange: this.props.onChange,
      type: this.props.fieldType || 'number',
      onFocus,
      onBlur,
      name: field,
      ref: field,
      defaultValue: value.get(field)
    }
  }

  placeholderProps(value, field) {
    const fieldValue = value.get(field)
    return {
      hasValue: fieldValue,
      className: this.errorsClass(fieldValue),
      'data-placeholder': this.placeholder(fieldValue, field)
    }
  }


  render() {
    const legend = this.props.data.get('legend')
    const value = dateFieldValuesForForm(this.props.value)

    return (
      <FieldSet className={classnames('controls-group date-field-set', this.props.className)}>
        {legend && <legend>{legend}</legend>}
        <div className='date-field-groups controls-flexbox flex-container'>
          <Placeholder {...this.placeholderProps(value, 'month')}>
            <input {...this.fieldProps(value, 'month')} />
          </Placeholder>
          <Placeholder {...this.placeholderProps(value, 'day')}>
            <input {...this.fieldProps(value, 'day')} />
          </Placeholder>
          <Placeholder {...this.placeholderProps(value, 'year')}>
            <input {...this.fieldProps(value, 'year')} />
          </Placeholder>
        </div>
        <FieldErrors errors={this.props.errors} />
      </FieldSet>
    )
  }
}

Date.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.object,
  onChange: PropTypes.func,
  shouldShowErrors: PropTypes.bool,
  fieldType: PropTypes.string,
  value: PropTypes.instanceOf(Map)
}

export default formFieldFactory(<Date />)
