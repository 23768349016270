import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import FileIcon from './FileIcon'
import RemoveFile from './RemoveFile'

import FileUploadActions from '../../../../shared_actions/FileUploadActions'
import xIcon from '../../../../shared_assets/v2/close.svg'
import spinnerIconBlack from '../../../../pro/assets/images/spinner-icon-black.gif'
import Logger from '../../../../lib/NewLogger'

import './fileView.scss'

const FileView = props => {
  const downloadFile = () => {
    Logger.log({
      name: 'clicked_download_attachment',
      payload: {
        actor: props.actor,
        context: props.context,
        element_id: props.elementId,
        everplan_id: props.itemResponse.get('everplan-id'),
        item: props.itemName,
        type: props.eventType,
        wildcard: props.elementId
      }
    })

    FileUploadActions.downloadFile(props.id, props.name)
  }

  const stopFileUpload = () => props.stopFileUpload(props.id)

  const isEncryptingOrEncrypted = !props.uploadStatus || props.uploadStatus === 'encrypted' || props.uploadStatus === 'encrypting'

  const fileUploadStatus = () => {
    if (isEncryptingOrEncrypted || props.uploadStatus === 'Virus Detected')
      return ''
    else
      return props.uploadStatus
  }

  const {error, loaded, name, total, uploadStatus, id} = props
  const virusFound = uploadStatus === 'Virus Detected'
  const complete = (!error && (loaded === total))
  const uploading = uploadStatus === 'Uploading'
  const scanning = uploadStatus === 'Scanning for virus'
  const finishingUpload = uploadStatus === 'Finishing Upload'

  const fileErrorText = () => {
    if (props.error)
      return props.error
    if (virusFound)
      return 'Upload failed: virus detected. Please check your email for details.'
  }

  // hide file that has been marked for deletion.
  if (props.deleted) return <noscript />

  return (
    <div className='file-view flex-container' data-testid={`file-${id}`}>
      <FileIcon fileName={name} />
      <div className='flex-child'>
        <div className='file-details-container flex-container'>
          <div className='file-details flex-child'>
            <div className='file-name'>
              {isEncryptingOrEncrypted ? <a onClick={downloadFile}>{name}</a> : <span>{name}</span>}
              {uploading && <img alt={`Remove ${name}`} className='xfile-icon' onClick={stopFileUpload} src={xIcon} />}
            </div>
            {!error && !complete && <progress className={classnames({complete}, 'progress-bar')} max={total} value={error ? 0 : loaded} /> }
            <div className='upload-status'>
              {(finishingUpload || scanning) && <img alt='spinner icon' src={spinnerIconBlack} className='encryption-spinner' />}
              {fileUploadStatus()}
            </div>
            {(error || virusFound) && <div className='error-description'>{fileErrorText()}</div>}
          </div>
          {
            (isEncryptingOrEncrypted || virusFound) &&
                <RemoveFile
                  removeFile={props.markFileAsDeleted}
                  isViewMode={props.isViewMode}
                  id={id}
                  name={name}
                  virusFound={virusFound}
                />
          }
        </div>
      </div>
    </div>
  )
}

FileView.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  deleted: PropTypes.bool,
  elementId: PropTypes.string,
  error: PropTypes.string,
  eventType: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  id: PropTypes.string,
  isViewMode: PropTypes.bool,
  itemName: PropTypes.string,
  loaded: PropTypes.number,
  markFileAsDeleted: PropTypes.func,
  name: PropTypes.string,
  stopFileUpload: PropTypes.func,
  total: PropTypes.number,
  uploadStatus: PropTypes.string
}

export default FileView
