import {fromJS, List, Map} from 'immutable'

export const FILE_SIZE_SUFFIXES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const ensureList = objectOrList => {
  if (List.isList(objectOrList))
    return objectOrList
  else if (!objectOrList || !Array.isArray(objectOrList))
    return List() // Return an empty List if junk like undefined or an incorrect type is passed. --BLR

  // We tried to be safe above--it's your own fault if you passed a date or something that will break this. --BLR
  return fromJS(objectOrList)
}

export const ensureMap = objectOrMap => {
  if (Map.isMap(objectOrMap))
    return objectOrMap
  else if (!objectOrMap || (typeof objectOrMap !== 'object'))
    return Map() // Return an empty Map if junk like undefined or an incorrect type is passed. --BLR

  // We tried to be safe above--it's your own fault if you passed a date or something that will break this. --BLR
  return fromJS(objectOrMap)
}

export const fileExtension = fileName => (fileName.includes('.') ? fileName.split('.').pop().toLowerCase() : '')

export const humanReadableFileSize = bytes => { // Based on a number of answers in this StackOverflow thread (BLR): https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
  if (!bytes || !Number.isInteger(bytes) || bytes <= 0)
    return '0 Bytes'
  const correctSuffixIndex = Math.floor(Math.log(bytes) / Math.log(1000))
  return `${Number((bytes / Math.pow(1000, correctSuffixIndex)).toFixed(2))} ${FILE_SIZE_SUFFIXES[correctSuffixIndex]}`
}

/*
  Helper for merging two Maps, each of which has field ID keys and values that are Lists of errors. Example:
  const immutable = require('immutable')
  const fromJS = immutable.fromJS
  const List = immutable.List
  const Map = immutable.Map
  const Set = immutable.Set
  const frontendErrors = fromJS({
    email: ['Frontend email error'], // Unique to the frontend
    name: { // Key shared by frontend and server
      first_name: ['Frontend first name error'] // Key shared by frontend and server
    }
  })
  const serverErrors = fromJS({
    relationship: ['Server relationship error'], // Unique to the server
    name: { // Key shared by frontend and server
      first_name: ['Server first name error'], // Key shared by frontend and server
      last_name: ['Server last name error'] // Unique to the server
    }
  })

  // Then the results (pretty printed, though the retain deep immutability):
  frontendErrors.mergeWith(errorsMerger, serverErrors)
  {
    "email": ["Frontend email error"], // Unchanged because key only appeared in one Map of errors
    "name": {
      "first_name": [ // Combination of frontend and server errors
        "Frontend first name error",
        "Server first name error"
      ],
      "last_name": ["Server last name error"] // Unchanged because key only appeared in one Map of errors
    },
    "relationship": ["Server relationship error"] // Unchanged because key only appeared in one Map of errors
  }
*/

export const errorsMerger = (errorsFromMapOne, errorsFromMapTwo) => {
  // Handle complex error Maps like those from the Name component:
  if (Map.isMap(errorsFromMapOne) && Map.isMap(errorsFromMapTwo))
    return errorsFromMapOne.mergeWith(errorsMerger, errorsFromMapTwo)

  // Handle simple error Lists for keys present in both places:
  if (List.isList(errorsFromMapOne) && List.isList(errorsFromMapTwo))
    return errorsFromMapOne.toSet().union(errorsFromMapTwo.toSet()).toList()

  // If key is only present in first Map then return that value unchanged:
  if (!errorsFromMapTwo)
    return errorsFromMapOne

  // If key is only present in second Map then return that value unchanged:
  if (!errorsFromMapOne)
    return errorsFromMapTwo
}

/**
 * Find the selected id of a select field in a form
 *
 * @param {string} selectId the select field
 * @param {Object} form from refs.form
 *
 * @returns {string} id of the selected value in a form
 */

export const findSelectedValueId = ({selectId, form}) => {
  if (form) {
    const formData = form.formData()

    return formData.getIn([selectId, 'items'])
      .find(itemList => itemList.get('value') === formData.getIn([selectId, 'value']))
      .get('id')
  }
}
